import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/data-and-analytics.jpg'

const pageTitle = "Data and Analytics";
const pageSlug = "data-and-analytics";


class DataAnalytics extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">In order to remain competitive, every company in today's market must have access to concise technical knowledge.</h6>
        </div>
</section>

<section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                 <p>Information has become a valuable resource, and every enterprise must begin to treat it as one. Making the most of your data to drive business insights, process improvement, automation, and creativity. Create a framework that ensures efficiency.
                  </p>
                  <p>Don't be left behind, your rivals use their data for more than just reporting. Through the internet, artificial intelligence, and modern data structures, your company can also become a technology pioneer. Go beyond traditional business intelligence and you will be able to transform data into knowledge that can drive market insight and generate more value and opportunities for you.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>Using our Data & Analytics service to achieve your goals. </h4>
                  <span className="break"></span>
                  <h5>Modern Analytics Platform </h5>
                  <p>Technology will play a vital role in your future as new services no longer burden companies with expensive data storage and high start-up costs. Modernize data research with, state-of - the-art technology and scalable architectures. Using information to streamline your market knowledge while increasing your ROI.</p>
                  <h5>Machine learning </h5>
                  <p>Machine learning and data science offers new benefits that data analysis can bring on this scale. Use machine learning tools, to achieve cost-effective automation solutions and process improvement. </p>
                  <h5>Business Intelligence & Visualization </h5>
                  <p>Transforming ambiguous metrics into observations that create value. User-friendly applications will allow users to explore more of their statistics and data, making the decision-making process easier. We can help you build a powerful, effective, user-friendly and informative experience.</p>
                  <h5>Data Quality </h5>
                  <p>In market concepts, metric creation, information reconciliation and reporting, the reliability of your data is critical. Let us help you improve the performance of your data management, components of software and master data management.</p>
                  <h5>Help & Maintenance </h5>
                  <p>We will assist in handling the processes for information analysis. For Data & Analytics tools, we will give you support and maintenance services.  Our experts can tailor a solution based on your unique needs, regardless of your current stage.</p>
                  <p>The information quantity is only continuing to increase. We can be your partner to help you tap into this new value-creating opportunity to ensure the survival of your company, invest in data analytics.</p>
                  
                    
                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default DataAnalytics
